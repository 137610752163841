import React, { Component } from 'react';
import sortBy from 'lodash.sortby'
import slug from 'slug';
import style from './Press.module.css';
import { firestore, watchUserRole } from '../firebase';
import ItemEditor from '../components/ItemEditor';
import TextItem from '../components/TextItem';
import Heading from '../components/Heading';
import Button from '../components/Button';
import Header from '../components/Header';

export default class Press extends Component {
    items = []
    state = {
        isAdmin: false,
        editingNewsId: null,
    }

    _setIsAdmin = isAdmin => this.setState({isAdmin})
    _handleAddPress = () => this.setState({editingNewsId: 'new'})
    _editPress = item => this.setState({editingNewsId: item.id})
    _deletePress = item => item.ref.delete()
    _doneEditing = () => this.setState({editingNewsId: null})
    
    componentDidMount () {
        this.unsub = firestore.collection('press').onSnapshot(snap => {
            this.items = sortBy(snap.docs, doc => -doc.get('position'))
            this.forceUpdate()
        })
        this.authUnsub = watchUserRole(isAdmin => this._setIsAdmin(isAdmin))
    }

    componentWillUnmount () {
        this.unsub()
        this.authUnsub()
    }

    render () {
        const pressSlug = this.props.match.params.pressSlug

        return <div className={style.pageContainer}>
            <Header />

            {!pressSlug && <Heading large padTop>Press</Heading>}

            {!pressSlug && this.state.isAdmin && <Button label='+ New' variant='add' onClick={this._handleAddPress} />}

            {this.state.editingNewsId === 'new' && <ItemEditor 
                shouldShowTitleField
                shouldShowExtendedBodyField
                initialText=''
                initialImage=''
                initialDate=''
                onCancel={this._doneEditing}
                onSave={(newRecord) => {
                    firestore.collection('press').add({
                        ...newRecord,
                        position: this.items.length + 1
                    })
                    .then(this._doneEditing)
                }}     
            />}
            {this.items.filter(item => (!pressSlug || slug(item.get('title')) === pressSlug))
            .map((item, i) => <div key={item.id}>
                {this.state.editingNewsId === item.id
                    ? <ItemEditor 
                        shouldShowTitleField
                        shouldShowExtendedBodyField
                        initialText={item.get('markdownText')} 
                        onCancel={this._doneEditing}
                        initialImage={{image: item.get('image'), gsurl: item.get('gsurl')}}
                        initialDate={item.get('date')}
                        initialTitle={item.get('title')}
                        initialBody={item.get('body')}
                        initialProject={item.get('project')}
                        onSave={(newRecord) => {
                            item.ref.set({
                                ...newRecord,
                                position: this.items.length - i
                            })
                            .then(this._doneEditing)
                        }}     
                    />
                    : <TextItem 
                        shouldOwnPage={!!pressSlug}
                        item={item}
                        isAdmin={this.state.isAdmin}
                        deleteItem={() => this._deletePress(item)}
                        editItem={() => this._editPress(item)}
                    />}
            </div>)}
        </div>
    }
}
