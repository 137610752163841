import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB_95qPl7557-esIBwzNIODg9ymBZ-kLRA",
    authDomain: "karenasher-93faf.firebaseapp.com",
    databaseURL: "https://karenasher-93faf.firebaseio.com",
    projectId: "karenasher-93faf",
    storageBucket: "karenasher-93faf.appspot.com",
    messagingSenderId: "242658895548",
    appId: "1:242658895548:web:1d1936ebe89aedf45852b9"
};

let auth, currentUser, fetchRole, onAuthStateChanged, firestore, watchUserRole, logout, storage;

if (typeof window !== "undefined") {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }
    
    auth = firebase.auth();
    currentUser = () => auth.currentUser;
    fetchRole = userId => firestore.collection('roles').doc(userId).get()
    onAuthStateChanged = cb => auth.onAuthStateChanged(cb);
    firestore = firebase.firestore();
    storage = firebase.storage();
    logout = () => auth.signOut();

    // -> Promise(bool isAdmin)
    watchUserRole = cb => onAuthStateChanged(user => {
        if (!!user) {
            fetchRole(user.uid).then(userRole => {
                cb(userRole.exists && userRole.get('role') === 'Admin')
            })
        }
    })
}

export {auth, currentUser, fetchRole, onAuthStateChanged, firestore, logout, watchUserRole, storage}
export default firebase;

