import React from 'react';
import PhotoSeries from '../components/PhotoSeries';
import Heading from '../components/Heading';

const TheFullCatastrophe = () => <PhotoSeries
    seriesName='The Full Catastrophe'
    seriesHeading={<Heading bold center catastrophe large padTop>The Full Catastrophe</Heading>}
    seriesImage='https://firebasestorage.googleapis.com/v0/b/karenasher-93faf.appspot.com/o/art%2Fthe-full-catastrophe%2Fphotos%2FKarenAsher_PeachPit1jpg?alt=media'
    bucketName='art/the-full-catastrophe/photos'
/>

export default TheFullCatastrophe
