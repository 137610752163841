import React from 'react';
import PhotoSeries from '../components/PhotoSeries';
import style from './NoCause.module.css';
import Heading from '../components/Heading';

const NoCauseForConcern = () => <PhotoSeries 
    seriesName='No Cause For Concern'
    seriesHeading={<Heading textfit noCause center large padTop>NO CAUSE FOR CONCERN</Heading>}
    seriesHeaderStyle={style.HeaderBG}
    bucketName='art/no-cause-for-concern/photos'
/>

export default NoCauseForConcern
