import React from 'react';
import './App.css';
import Home from './routes/Home';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import NoCauseForConcern from './routes/NoCause';
import TheFullCatastrophe from './routes/FullCatastrophe';
import Class from './routes/Class';
import News from './routes/News';
import Press from './routes/Press';
import Bio from './routes/Bio';
import Cv from './routes/Cv';
import Contact from './routes/Contact';
import CrapShoot from './routes/Crap';
import Login from './routes/Login';
import PageContainer from './components/PageContainer';
import Heading from './components/Heading';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>

          <Route exact path='/' component={Home} />
          <Route exact path='/photography' render={() => <Redirect to='/' />} />

          <Route exact path='/no-cause-for-concern' component={NoCauseForConcern} />
          <Route exact path='/the-full-catastrophe' component={TheFullCatastrophe} />
          <Route exact path='/crap' component={CrapShoot} />
          <Route exact path='/class' component={Class} />

          <Route exact path='/news' component={News} />
          <Route path="/press/:pressSlug?" component={Press} />

          <Route exact path='/cv' component={Cv} />
          <Route exact path='/bio' component={Bio} />
          <Route exact path='/contact' component={Contact} />

          <Route exact path='/login' component={Login} />

          <Route path="*">
            <PageContainer>
              <Heading>404 - Page not found :(</Heading>
            </PageContainer>
          </Route>

        </Switch>
      </Router>
    </div>
  );
}

export default App;
