import React, { Component } from 'react';
import style from './ItemEditor.module.css';
import EditableFile from './EditableFile';

export default class ItemEditor extends Component {
    state = {
        editingNewsId: null,
        editedContent: null,
        editedImage: null,
        editedTitle: null,
        editedProject: null,
        editedBody: null,
        isShowingImages: false,
        editedDate: ''
    }

    constructor (props) {
        super(props)
        this.state.editedContent = props.initialText || ''
        this.state.editedImage = props.initialImage || ''
        this.state.editedThumbnail = props.initialThumbnail || ''
        this.state.editedDate = props.initialDate || ''
        this.state.editedTitle = props.initialTitle || ''
        this.state.editedBody = props.initialBody || ''
        this.state.editedProject = props.initialProject || ''
    }

    _handleSave = e => {
        e.preventDefault()
        
        const newRecord = {
            markdownText: this.state.editedContent, 
            gsurl: this.state.editedImage.gsurl ? this.state.editedImage.gsurl : '',
            image: this.state.editedImage.thumbnail ? this.state.editedImage.thumbnail : '',
            date: this.state.editedDate,
            project: this.state.editedProject
        }
        if (this.state.editedImage.path) {
            newRecord.path = this.state.editedImage.path
            newRecord.thumbnail = this.state.editedImage.thumbnail
        }
        if (this.props.shouldShowTitleField) {
            newRecord.title = this.state.editedTitle
        }
        if (this.props.shouldShowExtendedBodyField) {
            newRecord.body = this.state.editedBody
        }

        this.props.onSave(newRecord)
    }

    render () {
        const {shouldShowTitleField, shouldShowExtendedBodyField} = this.props

        return <form onSubmit={this._handleSave}>
            <div className={style.editor}>
                <EditableFile 
                    collectionName='images'
                    defaultThumbnail={this.state.editedImage.thumbnail} 
                    fileTypeName='image'
                    onFileChanged={image => {
                        this.setState({editedImage: image})
                    }}
                />
                <div className={style.contentEditorSection}>
                    <input type='text' placeholder='date' value={this.state.editedDate} onChange={e => this.setState({editedDate: e.target.value})} />
                    {shouldShowTitleField && <input type='text' placeholder='title' value={this.state.editedTitle} onChange={e => this.setState({editedTitle: e.target.value})} />}
                    <label htmlFor='blurb'>
                        <div>Blurb</div>
                        <textarea 
                            id='blurb'
                            className={style.textEditor}
                            onChange={e => this.setState({editedContent: e.target.value})}
                            value={this.state.editedContent}
                        />
                    </label>
                    {shouldShowExtendedBodyField && <div>
                        <label htmlFor='body'>
                            <div>Body text</div>
                            <textarea 
                                id='body'
                                className={style.textEditor}
                                onChange={e => this.setState({editedBody: e.target.value})}
                                value={this.state.editedBody}
                            />
                        </label>
                    </div>}
                    <label htmlFor='project'>
                        Related to project:
                        <select onChange={e => this.setState({editedProject: e.target.value})}>
                            <option />
                            <option selected={this.state.editedProject === 'nocause'}>nocause</option>
                            <option selected={this.state.editedProject === 'fullcatastrophe'}>fullcatastrophe</option>
                            <option selected={this.state.editedProject === 'class'}>class</option>
                            <option selected={this.state.editedProject === 'crapshoot'}>crapshoot</option>
                        </select>
                    </label>
                </div>
            </div>
            <div className={style.buttons}>
                <button onClick={e => {
                    e.preventDefault()
                    this.props.onCancel()
                }} className={style.cancelButton}>Cancel</button>
                <button type='submit' className={style.saveButton}>Save</button>
            </div>
        </form>
    }
}