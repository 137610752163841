import React, { Component } from 'react'
import firebase from '../firebase'
import 'firebaseui/dist/firebaseui.css'

export default class Login extends Component {
    constructor (props) {
        super(props)
        if (typeof window !== 'undefined') {
            this.firebaseui = require('firebaseui')
        }
    }
    componentDidMount () {
        var ui = new this.firebaseui.auth.AuthUI(firebase.auth())
        
        ui.start('#firebaseui-auth-container', {
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    return true;
                },
                uiShown: function() {
                    // The widget is rendered.
                    // Hide the loader.
                    document.getElementById('loader').style.display = 'none';
                }
            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'popup',
            signInSuccessUrl: '/',
            signInOptions: [
                // Leave the lines as is for the providers you want to offer your users.
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false
                }
                // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
                // firebase.auth.GithubAuthProvider.PROVIDER_ID,
                // firebase.auth.PhoneAuthProvider.PROVIDER_ID
            ],
            // Terms of service url.
            tosUrl: '<your-tos-url>',
            // Privacy policy url.
            privacyPolicyUrl: '<your-privacy-policy-url>'
        })
    }
    render () {
        return <div style={{maxWidth: '400px', margin: '0 auto'}}>
            <div id="firebaseui-auth-container"></div>
            <div id="loader">Loading...</div>
        </div>
    }
}
