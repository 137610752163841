import React, { Component } from 'react';
import sortBy from 'lodash.sortby'
import style from './News.module.css';
import { firestore, watchUserRole } from '../firebase';
import ItemEditor from '../components/ItemEditor';
import Header from '../components/Header';
import Heading from '../components/Heading';
import Markdown from '../components/Markdown';

export default class News extends Component {
    news = []
    state = {
        isAdmin: false,
        editingNewsId: null,
    }

    _setIsAdmin = isAdmin => this.setState({isAdmin})
    _handleAddNews = () => this.setState({editingNewsId: 'new'})
    _editNews = item => this.setState({editingNewsId: item.id})
    _deleteNews = item => item.ref.delete()
    _doneEditing = () => this.setState({editingNewsId: null})
    
    componentDidMount () {
        this.unsub = firestore.collection('news').onSnapshot(snap => {
            this.news = sortBy(snap.docs, doc => -doc.get('position'))
            this.forceUpdate()
        })
        this.authUnsub = watchUserRole(isAdmin => this._setIsAdmin(isAdmin))
    }

    componentWillUnmount () {
        this.unsub()
        this.authUnsub()
    }

    render () {
        return <div className={style.pageContainer}>
            <Header />

            <Heading large padTop>News</Heading>

            {this.state.isAdmin && <button className={style.addButton} onClick={this._handleAddNews}>Add new one</button>}

            {this.state.editingNewsId === 'new' && <ItemEditor 
                initialText=''
                initialImage=''
                initialDate=''
                onCancel={this._doneEditing}
                onSave={({markdownText, image, date}) => {
                    firestore.collection('news').add({
                        date,
                        markdownText,
                        image,
                        position: this.news.length + 1
                    })
                    .then(this._doneEditing)
                }}     
            />}
            {this.news.map((newsItem, i) => <div key={newsItem.id}>
                {this.state.editingNewsId === newsItem.id
                    ? <ItemEditor 
                        initialText={newsItem.get('markdownText')} 
                        onCancel={this._doneEditing}
                        initialImage={{thumbnail: newsItem.get('image')}} 
                        initialDate={newsItem.get('date')}
                        onSave={({markdownText, image, date}) => {
                            newsItem.ref.set({
                                date,
                                markdownText,
                                image,
                                position: this.news.length - i
                            })
                            .then(this._doneEditing)
                        }}     
                    />
                    : <div className={style.sectionContent}>
                            <div className={style.imageContainer}>
                                <img alt='' className={style.displayedImage} src={newsItem.get('image')} />
                            </div>
                            <div className={style.displayedContent}>
                                <div className={style.itemDate}>{newsItem.get('date')}</div>
                                <Markdown markdown={newsItem.get('markdownText') ? newsItem.get('markdownText') : ''} />
                                {this.state.isAdmin && <div>
                                    <button className={style.deleteButton} onClick={() => this._deleteNews(newsItem)}>Delete</button>
                                    <button className={style.editButton} onClick={() => this._editNews(newsItem)}>Edit</button>
                                </div>}
                            </div>
                        </div>}
            </div>)}
        </div>
    }
}
