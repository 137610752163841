import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {Keyboard} from 'keyboard-js'
import style from './PhotoSeries.module.css';
import sortBy from 'lodash.sortby'
import slug from 'slug'
import { firestore, watchUserRole } from '../firebase'
import Button from './Button';
import PhotoEditor from './PhotoEditor';
import Header from './Header';
import EditableFile from './EditableFile';
import { rewriteSignedUrls } from '../utils';

const PhotoDetail = ({isVideo,photo,}) => <div className={style.PhotoDetail}>
    {isVideo ? <video autoplay muted loop playsinline className={style.video}>
        <source src={photo.get('path')}
                type="video/mp4" />

        Sorry, your browser doesn't support embedded videos.
    </video> : <img alt='' src={rewriteSignedUrls(photo.get('path'))} />}
</div>

export default class PhotoSeries extends Component {
    photos = []
    state = {
        seriesDescription: '',
        isAdmin: false,
        editingPhotoId: null,
        viewingPhotoPosition: null
    }

    static propTypes = {
        seriesName: PropTypes.string.isRequired,
        docID: PropTypes.string,
        isVideo: PropTypes.bool
    }

    constructor (props) {
        super(props)
        this.keyboard = new Keyboard()
        this.seriesRef = firestore.collection('art').doc(slug(props.docID || props.seriesName).toLowerCase())
        this.photoCollection = this.seriesRef.collection(props.collectionName || 'photos')
    }

    _setIsAdmin = isAdmin => this.setState({isAdmin})
    _editPhoto = item => this.setState({editingPhotoId: item.id, editedVideo: item.get('path')})
    _deletePhoto = item => item.ref.delete()
    _doneEditing = () => this.setState({editingPhotoId: null})
    _handleAddPhoto = () => this.setState({editingPhotoId: 'new'})
    
    componentDidMount () {
        this.keyboard.register('next', this._goNext, 'ArrowRight')
        this.keyboard.register('prev', this._goPrev, 'ArrowLeft')
        this.keyboard.register('dismiss', this._dismissViewingPhoto, 'Escape')
        this.keyboard.start()

        this.seriesUnsub = this.seriesRef.onSnapshot(snap => {
            if (snap.get('description') !== null && snap.get('description') !== this.state.seriesDescription) {
                this.setState({seriesDescription: snap.get('description')})
            }
        })
        this.photoCollectionUnsub = this.photoCollection.onSnapshot(snap => {
            this.photos = sortBy(snap.docs, doc => -doc.get('position'))
            this.forceUpdate()
        })
        this.authUnsub = watchUserRole(isAdmin => this._setIsAdmin(isAdmin))
    }

    componentWillUnmount () {
        this.seriesUnsub()
        this.photoCollectionUnsub()
        this.authUnsub()
        this.keyboard.unregister('next')
        this.keyboard.unregister('prev')
        this.keyboard.unregister('dismiss')
        this.keyboard.end()
    }
    _dismissViewingPhoto = () => this.setState({viewingPhotoPosition: null})
    _viewingPosition = () => this.state.viewingPhotoPosition
    _goPrev = () => {
        if (!this._hasPrev()) return

        this.setState({viewingPhotoPosition: this._viewingPosition() + 1})
    }
    _goNext = () => {
        if (!this._hasNext()) return

        this._hasNext() && this.setState({viewingPhotoPosition: this._viewingPosition() - 1})
    }
    _hasPrev = () => !!this.photos.find(p => this._viewingPosition() + 1 === p.get('position'))
    _hasNext = () => {
        return !!this.photos.find(p => this._viewingPosition() - 1 === p.get('position'))
    }

    render () {
        const {seriesHeading, seriesImage, bucketName='photos', showHeader=true, seriesHeaderStyle} = this.props
        const {seriesDescription} = this.state
        
        return <div>
            {showHeader && <div className={style.pageContainer}>
                <Header />
            </div>}

            {showHeader && <div className={seriesHeaderStyle || style.HeaderBG}>
                <div className={style.pageContainer} style={{textAlign: 'center'}}>
                    {seriesHeading}
                    {!!seriesDescription && <p>{seriesDescription}</p>}
                    {!!seriesImage && <img alt='' style={{
                        border: '20px solid white', 
                        maxWidth: '350px',
                        marginTop: '32px',
                        marginBottom: '64px',
                        boxShadow: '2px 8px 2px rgba(0,0,0,0.8)'
                    }} src={seriesImage} />}
                </div>
            </div>}

            <div className={style.pageContainer}>                
                {this.state.isAdmin && <Button label='New photo' onClick={this._handleAddPhoto} variant='add' />}
                {this.state.editingPhotoId === 'new' && <PhotoEditor 
                    isVideo={this.props.isVideo}
                    initialText=''
                    initialThumbnail=''
                    bucketName={bucketName}
                    onCancel={this._doneEditing}
                    onSave={async ({text='', gsurl}) => {
                        const updateData = {
                            text,
                            gsurl,
                            position: this.photos.length + 1
                        };

                        const snaps = await this.photoCollection.where('gsurl', '==', gsurl).get()
                        if (snaps.empty) {
                            await this.photoCollection.add(updateData)
                        } else {
                            await this.photoCollection.doc(snaps.docs[0].id).set(updateData, {merge: true})
                        }

                        this._doneEditing()
                    }}
                />}
                
                <div className={style.photoGridContainer}>
                    {this.photos.map((photo, i) => <div className={style.photo} key={i}>
                        {this.state.editingPhotoId === photo.id ? <div>
                            {this.props.isVideo && <EditableFile 
                                onFileChanged={({gsurl, previewPath}) => this.setState({editedVideo: previewPath})} 
                                collectionName={bucketName}
                                fileTypeName='Videos'
                            />}
                            <PhotoEditor 
                                isVideo={this.props.isVideo}
                                initialText={photo.get('text')}
                                initialThumbnail={photo.get('thumbnail')}
                                bucketName={this.props.isVideo ? bucketName + '_cover_images' : bucketName}
                                onCancel={this._doneEditing}
                                onSave={async editedPhoto => {
                                    const path = this.props.isVideo 
                                        ? this.state.editedVideo 
                                        : editedPhoto.path 
                                            ? editedPhoto.path 
                                            : ''

                                    await photo.ref.set({
                                        path: path,
                                        text: editedPhoto.text || '',
                                        gsurl: editedPhoto.gsurl,
                                        thumbnail: editedPhoto.thumbnail,
                                        rawImage: editedPhoto.path || ''
                                    }, {merge: true})
                                    
                                    this._doneEditing()
                                }}
                            /></div> : <div onClick={() => this.setState({viewingPhotoPosition: photo.get('position')})}>
                                <img alt='' className={style.photoImg} src={rewriteSignedUrls(photo.get('thumbnail'))} />
                            </div>}
                        
                            {photo.get('position') === this.state.viewingPhotoPosition && <div className={style.popupPhotoContainer}>

                                <div className={style.popupPhoto}>
                                    <button
                                        className={style.closeButton}
                                        onClick={this._dismissViewingPhoto}
                                    >&times;</button>

                                    <PhotoDetail 
                                        isVideo={this.props.isVideo}
                                        photo={photo}
                                    />
                                    <div className={style.photoControls}>
                                        <button onClick={this._goPrev} disabled={!this._hasPrev()} className={style.prev}>&larr;</button>
                                        <div className={style.photoText}>{photo.get('text')}</div>
                                        <button onClick={this._goNext} disabled={!this._hasNext()} className={style.next}>&rarr;</button>
                                    </div>
                                    
                                </div>
                                
                                <div className={style.overlay} />
                            </div>}

                        {this.state.isAdmin && <div>
                            <Button variant='delete' onClick={() => this._deletePhoto(photo)} label='Delete' />
                            <Button onClick={() => this._editPhoto(photo)} label='Edit' />
                        </div>}
                    </div>)}
                </div>
            </div>
        </div>
    }
}
