import React from 'react';
import Heading from './Heading';
import Link from './Link';
import { currentUser, logout } from '../firebase';

const Header = ({isAdmin}) => (
	<header>
		<Heading padTop><Link plain to='/'>Karen Asher</Link></Heading>

		<div>
			<Heading inline>Video</Heading>
			<Link inline to='/class'>Class</Link>
		</div>

		<div>
			<Heading inline>Photo</Heading>
			<Link inline to='/the-full-catastrophe'>The Full Catastrophe</Link>
			<Link inline to='/no-cause-for-concern'>No Cause For Concern</Link>
			<Link inline to='/crap'>crapshoot</Link>
		</div>

		<div>
			<Heading inline>Text</Heading>
			<Link inline to='/news'>News</Link>
			<Link inline to='/press'>Press</Link>
			<Link inline to='/bio'>Bio</Link>
			<Link inline to='/cv'>CV</Link>
			<Link inline to='/contact'>Contact</Link>
		</div>

		{currentUser() && <button onClick={logout}>log out</button>}
	</header>
);

export default Header;
