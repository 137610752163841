import React from 'react';
import PhotoSeries from '../components/PhotoSeries';
import Heading from '../components/Heading';
import style from './Crap.module.css';

const CrapShoot = () => <PhotoSeries
    headerBg='#ff66b4'
    seriesName='Crap Shoot'
    seriesHeading={<Heading textfit large padTop>crapshoot</Heading>}
    seriesHeaderStyle={style.HeaderBG}
    bucketName='art/crap-shoot/photos'
/>

export default CrapShoot
