import React from 'react';
import style from './Button.module.css';

const Button = ({label, onClick, variant='ghost', type='button', disabled=false}) => (
    <button 
        className={style[buttonStyleName(variant)]} 
        onClick={onClick} 
        type={type}
        disabled={disabled}
    >{label}</button>
);

function buttonStyleName (styleName) {
    const allowedStyles = ['add', 'delete', 'ghost']
    if (allowedStyles.indexOf(styleName) < 0) {
        throw new Error(styleName + ' is not an allowed button style.')
    }

    return styleName
}

export default Button
