import React from 'react'
import marked from 'marked'
import style from './Markdown.module.css'

export default ({markdown, append=''}) => <div className={style.markdown} style={{position: 'relative'}}>
    <div dangerouslySetInnerHTML={{__html: marked(markdown)}} />
    <div style={{position: 'absolute', right: 0, bottom: '42px'}}>
        {!!append && append}
    </div>
</div>
