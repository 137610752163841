import React from 'react';
import { Link as RLink } from 'react-router-dom';
import style from './Link.module.css';

const Link = ({children, to, padLeft=false, light=false, inline=false, plain=false}) => <RLink 
    // activeClassName='active'
    className={plain ? style.plain : [
        style.link,
        padLeft && style.padLeft,
        light && style.light,
        inline && style.inline
    ].join(' ')} to={to}>{children}</RLink>

export default Link
