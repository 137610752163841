import React, { Component} from 'react'
import style from './Neon.module.css'

export default class Neon extends Component {
    state = {
        isOn: false
    }

    componentDidMount () {
		this.intervalId = setInterval(() => {
			this.setState({isOn: !this.state.isOn})
		}, 4000)
	}

	componentWillUnmount () {
		clearInterval(this.intervalId)
	}

    render () {
        const {text, blinkingText, small=false} = this.props

        return <div className={[style.outer, small && style.outer__small].join(' ')}>

                <span className={[style.text, small && style.small].join(' ')}>{text}</span>
                <span className={[
                        style.blinkingText, 
                        this.state.isOn && style.blinkingText__on
                    ].join(' ')
                }>{blinkingText}</span>

        </div>
    }
}
