import React, { Component } from 'react';
import style from './ItemEditor.module.css';
import EditableFile from './EditableFile';
import Button from './Button';

export default class PhotoEditor extends Component {
    state = {
        editedText: null,
        editedImageGsurl: null,
        isShowingImages: false,
        editedThumbnail: '',
        path: ''
    }

    constructor (props) {
        super(props)
        this.state.editedText = props.initialText
        this.state.editedThumbnail = props.initialThumbnail
        this.state.editedPath = props.initialPath
    }

    render () {
        const {bucketName} = this.props;

        return <form onSubmit={e => {
            e.preventDefault()
            this.props.onSave({
                text: this.state.editedText, 
                gsurl: this.state.editedImageGsurl,
                thumbnail: this.state.editedThumbnail,
                path: this.state.editedPath
            })
        }}>
            <div className={style.editor}>
                <EditableFile 
                    defaultThumbnail={this.state.editedThumbnail} 
                    onFileChanged={({gsurl, previewPath='', thumbnail='', path=''}) => this.setState({
                        editedImageGsurl: gsurl,
                        editedThumbnail: previewPath.length > 0 ? '' : thumbnail,
                        editedPath: previewPath.length > 0 ? '' : path,
                    })} 
                    collectionName={bucketName}
                />
                <div className={style.contentEditorSection}>
                    <input 
                        onChange={e => this.setState({editedText: e.target.value})}
                        value={this.state.editedText}
                    />
                </div>
            </div>
            <div className={style.buttons}>
                <Button onClick={this.props.onCancel} variant='delete' label='Cancel' />
                <Button type='submit' label='Save' />
            </div>
        </form>
    }
}