import React, { Component } from 'react';
import PropTypes from 'prop-types';
import slug from 'slug';
import style from './EditableFile.module.css';
import firebase, { storage, firestore } from '../firebase';
import { rewriteSignedUrls } from '../utils';

export default class EditableFile extends Component {
    state = {
        isShowingFiles: false,
		editedFile: null,
		thumbnail: '',
		isUploading: false,
		uploadProgress: 0
	}
	
	static propTypes = {
		collectionName: PropTypes.string.isRequired,
		fileTypeName: PropTypes.string.isRequired,
	}

    constructor (props) {
		super(props)
        this.state.thumbnail = props.defaultThumbnail
    }

    _hideFiles = () => this.setState({isShowingFiles: false})
    _showFiles = e => {
        e.preventDefault()
		this.setState({isShowingFiles: true})
    }
    _handleUseNewFile = (gsurl, previewPath) => {
        this.setState(
			{isUploading: false, thumbnail: previewPath, editedFile: gsurl, isShowingFiles: false}, 
			() => this.props.onFileChanged({gsurl, previewPath})
		)
	}
	_handleUseExistingFile = (gsurl, previewPath, rawPath) => {
		this.setState(
			{isUploading: false, thumbnail: previewPath, editedFile: gsurl, isShowingFiles: false}, 
			() => this.props.onFileChanged({gsurl, thumbnail: previewPath, path: rawPath})
		)
	}

	_addFile = file => {
		this.setState({isUploading: true})
		const storageRef = storage.ref();
		const uploadTask = storageRef.child(this.props.collectionName + '/' + slug(file.name)).put(file)
		
		uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
			snapshot => {
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				
				switch (snapshot.state) {
					case firebase.storage.TaskState.PAUSED: // or 'paused'
						// console.log('Upload is paused')
						break
					case firebase.storage.TaskState.RUNNING: // or 'running'
						this.setState({uploadProgress: progress})
						// console.log('Upload is running')
						break
					default:
						break
				}
			}, error => {

				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				switch (error.code) {
					case 'storage/unauthorized':
						console.error("User doesn't have permission to access the object")
						break;

					case 'storage/canceled':
						// console.log('User canceled the upload')
						break;

					case 'storage/unknown':
					default:
						// console.log(error.serverResponse)
						console.error('Unknown error occurred')
						break;
				}
			}, async () => {
				const previewPath = await uploadTask.snapshot.ref.getDownloadURL()
				this._handleUseNewFile(uploadTask.snapshot.ref.toString(), previewPath)
			});
	}

    render () {
		const {fileTypeName, collectionName} = this.props

        return <div>
            <div className={style.fileEditorContainer}>
                                    
                <div className={style.fileContainer}>
                    <img alt='' className={style.displayedFile} src={this.state.thumbnail} />
                </div>
                {this.state.isShowingFiles && <div className={style.fileList}>
                    <button className={style.fileListCloseButton} onClick={e => {
                        e.preventDefault()
                        this.setState({isShowingFiles: false})
                    }}>&times;</button>
                    <ul>
                        <li>
							{this.state.isUploading 
								? <div style={{
									width: this.state.uploadProgress + '%',
									transition: '.1s width',
									backgroundColor: 'rgba(138, 43, 226, 0.7)',
									height: '24px'
								}}></div>
								: <label htmlFor="fileInput" className={style.addNewFileButton}>Add {fileTypeName}</label>}
						</li>
                        <input 
                            type='file' 
                            id='fileInput'
                            style={{display: 'none'}}
                            ref={fileInput => { this.fileInput = fileInput }} 
                            onChange={e => this._addFile(e.target.files.item(0))}
                        />
                        <UploadedFileList collectionName={collectionName} onClickFile={this._handleUseExistingFile} />
                    </ul>
                </div>}
				<button className={style.fileButton} onClick={this._showFiles}>Change {fileTypeName}</button>
            </div>
        </div>
    }
}

class UploadedFileList extends Component {
	fileList = []

	state = {
		hasLoaded: false,
	}

	static propTypes = {
		collectionName: PropTypes.string.isRequired
	}

	async componentDidMount () {
		this.unsub = firestore.collection(this.props.collectionName).onSnapshot(snap => {
			this.fileList = snap.docs
			this.forceUpdate()
		})
		this.setState({hasLoaded: true})
	}

	render () {
		if (!this.state.hasLoaded) {
			return <li>&hellip; loobing &hellip;</li>
		}

		if (this.state.hasLoaded && this.fileList.length === 0) {
			return <li style={{opacity: 0.5}}>no files yet</li>
		}


		return <div style={{
			height: '300px',
			overflowY: 'scroll'
		}}>
			{this.fileList.map(file => {
				return <div 
					key={file.id}
					onClick={() => this.props.onClickFile(file.get('gsurl'), file.get('thumbnail'), file.get('path'))}
					className={style.filePreviewListItem} 
				>
					{file.get('thumbnail') 
						? <img alt='' className={style.imgPreview} src={rewriteSignedUrls(file.get('thumbnail'))} />
						: <span>{file.get('path')}</span>}
				</div>
			})}
		</div>
	}
}
