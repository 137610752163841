import React, { Component } from 'react';
import style from './Home.module.css';
import { logout } from '../firebase';
import Neon from '../components/Neon';
import Link from '../components/Link';
import Heading from '../components/Heading';
import { viewportWidth } from '../utils';

class Home extends Component {
	sections = ['newsMarkdown', 'showsMarkdown', 'contactMarkdown', 'linksMarkdown']
	sectionNames = {
		newsMarkdown: 'News',
		showsMarkdown: 'Shows',
		contactMarkdown: 'Contact',
		linksMarkdown: 'Links'
	}
	state = {
		newsMarkdown: '',
		showsMarkdown: '',
		contactMarkdown: '',
		linksMarkdown: '',
		isAdmin: false
	}

	_logout = () => logout()

	render () {
		return <div>
			<video autoPlay muted loop playsInline className={style.video}>
				<source src='https://firebasestorage.googleapis.com/v0/b/karenasher-93faf.appspot.com/o/hailey-pose.mp4?alt=media'
						type="video/mp4" />
				<source src='https://firebasestorage.googleapis.com/v0/b/karenasher-93faf.appspot.com/o/hailey-pose.webm?alt=media'
						type="video/webm" />

				Sorry, your browser doesn't support embedded videos.
			</video>

			<div style={{
				position: 'fixed',
				zIndex: 10,
				top: '16px',
				left: '16px'
			}}>
				<div>
					<Neon small text='Karen Asher' />
				</div>

				<div className={style.homeNav}>
					{/* <Link href='/admin'>Admin</Link> */}
					<Heading light sub padTop>Photo</Heading>
					<Link padLeft={ viewportWidth > 600} light to='/the-full-catastrophe'>The Full Catastrophe</Link>
					<Link padLeft={ viewportWidth > 600} light to='/no-cause-for-concern'>No Cause For Concern</Link>
					<Link padLeft={ viewportWidth > 600} light to='/crap'>crapshoot</Link>

					<Heading light sub padTop>Video</Heading>
					<Link padLeft={ viewportWidth > 600} light to='/class'>Class</Link>

					<Heading light sub padTop>Text</Heading>
					<Link padLeft={ viewportWidth > 600} light to='/news'>News</Link>
					<Link padLeft={ viewportWidth > 600} light to='/press'>Press</Link>
					<Link padLeft={ viewportWidth > 600} light to='/bio'>Bio</Link>
					<Link padLeft={ viewportWidth > 600} light to='/cv'>CV</Link>
					<Link padLeft={ viewportWidth > 600} light to='/contact'>Contact</Link>
				</div>
			</div>
			
			
		</div>
	}
}

export default Home;
