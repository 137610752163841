import React from 'react'
import style from './Heading.module.css'
import textFit from 'textfit'
import { viewportWidth } from '../utils'

class Heading extends React.PureComponent {
    el = null

    componentDidMount () {
        if (this.props.textfit) {
            textFit(this.el)
        }
    }

    render () {
        const {
            children, 
            light=false, 
            sub=false, 
            padTop=false, 
            inline=false, 
            large=false,
            neon=false,
            center=false,
            catastrophe=false,
            noCause=false,
            textfit=false,
            bold=false,
        } = this.props

        const headerStyle = {height: textfit ? '132px' : 'auto'}
        if (textfit) {
            headerStyle.fontWeight = textFit && viewportWidth < 800 ? '700' : '200'
        }

        return <div 
            ref={el => this.el = el}
            style={headerStyle}
            className={[
                style.heading, 
                light && style.light, 
                sub && style.subHeading,
                padTop && style.padTop,
                inline && style.inline,
                large && style.large,
                bold && style.bold,
                neon && style.heading_neon,
                center && style.center,
                catastrophe && style.catastrophe,
                noCause && style.noCause,
            ].join(' ')}
        >{children}</div>
    }
}

export default Heading
