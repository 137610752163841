import React, {Component} from 'react'
import PageContainer from './PageContainer'
import { watchUserRole, firestore } from '../firebase'
import Button from './Button'
import Markdown from './Markdown'

export default class ContentPage extends Component {
    content = ''
    state = {
        isAdmin: false,
        isEditing: false,
        editedContent: '',
    }

    _setIsAdmin = isAdmin => this.setState({isAdmin})
    _doneEditing = () => this.setState({isEditing: false})
    _handleSave = e => {
        e.preventDefault()
        firestore.collection('content').doc(this.props.docID).set({markdownText: this.state.editedContent})
        this.setState({isEditing: false})
    }
    
    componentDidMount () {
        this.unsub = firestore.collection('content').doc(this.props.docID).onSnapshot(snap => {
            this.content = snap.get('markdownText')
            this.setState({editedContent: this.content})
        })
        this.authUnsub = watchUserRole(isAdmin => this._setIsAdmin(isAdmin))
    }

    componentWillUnmount () {
        this.unsub()
        this.authUnsub()
    }

    render () {
        return <PageContainer pageTitle={this.props.pageTitle}>
            {this.state.isAdmin && <Button label='Edit' onClick={() => this.setState({isEditing: true})} />}
            {this.state.isEditing 
                ? <form onSubmit={this._handleSave}>
                    <textarea value={this.state.editedContent} onChange={e => this.setState({editedContent: e.target.value})} />
                    <Button type='submit' label='Save' />
                </form>
                : <Markdown markdown={this.content} />}
        </PageContainer>
    }
}
