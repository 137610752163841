import React, {Component} from 'react'
import PageContainer from '../components/PageContainer'
import Button from '../components/Button'

export default class Contact extends Component {
    state = {
        isLoading: false,
        successMessage: null,
        errorMessage: null
    }

    render () {
        const {successMessage, errorMessage, isLoading} = this.state
        
        return <PageContainer pageTitle='Contact'>
            <form id='contact-form' onSubmit={async e => {
                e.preventDefault()
                if (window !== undefined) {
                    this.setState({isLoading: true})
                    try {
                        const result = await postData(
                            'https://us-central1-karenasher-93faf.cloudfunctions.net/api/sendMessage',
                            {
                                message: document.getElementById('message').value, 
                                email: document.getElementById('email').value
                            }
                        )
                        
                        if (result.success) {
                            this.setState({
                                successMessage: 'Your message was sent, thank you! I\'ll reply soooon!',
                                errorMessage: null,
                                isLoading: false
                            })
                        } else {
                            this.setState({
                                successMessage: null,
                                errorMessage: result.error,
                                isLoading: false
                            })
                        }
                    } catch (err) {
                        this.setState({
                            successMessage: null,
                            errorMessage: String(err),
                            isLoading: false
                        })
                    }
                }
            }}>
                <label htmlFor='email' style={{fontWeight: 'bold', lineHeight: 1.6, display: 'block', marginBottom: '24px'}}>
                    <div>
                        Your email address
                    </div>
                    <input id='email' name='email' type='email' style={{fontSize: '18px', lineHeight: 1.5, padding: '4px'}} />
                </label>

                <label htmlFor='message' style={{fontWeight: 'bold', lineHeight: 1.6, display: 'block', marginBottom: '24px'}}>
                    <div>
                        Your message
                    </div>
                    <textarea id='message' name='message' rows='10' cols='80' style={{maxWidth: '100%', fontSize: '18px', lineHeight: 1.5, padding: '4px'}} />
                </label>

                {!!successMessage && <div style={{color: 'green', fontWeight: 'bold'}}>{successMessage}</div>}
                {!!errorMessage && <div style={{color: 'red', fontWeight: 'bold'}}>{errorMessage}</div>}

                <Button 
                    label={isLoading ? 'Please wait...' : 'Send'} 
                    variant='add' 
                    type='submit' 
                    disabled={isLoading}
                />
            </form>
        </PageContainer>
    }
}

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}
