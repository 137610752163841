import React, { Component } from 'react';
import sortBy from 'lodash.sortby'
import { watchUserRole, firestore } from '../firebase';
import style from './Class.module.css'
import Neon from '../components/Neon';
import PhotoSeries from '../components/PhotoSeries';
import Header from '../components/Header';

class Class extends Component {
    videos = []
    photos = []

    state = {
        editedVideoGsurl: '',
        videoBeingEdited: null,
        editedPhotoGsurl: '',
        editedPhotoThumbnail: '',
        editedPhotoPath: '',
        photoBeingEdited: null,
        isOn: false,
        seriesDescription: '',
    }

    constructor (props) {
        super(props)
        this.seriesRef = firestore.collection('art').doc('class')
        this.stillPoseCollection = this.seriesRef.collection('videos')
        this.documentationCollection = this.seriesRef.collection('documentation')
    }

    _setIsAdmin = isAdmin => this.setState({isAdmin})
    _addVideo = () => this.setState({videoBeingEdited: 'new'})
    _addPhoto = () => this.setState({photoBeingEdited: 'new'})
    _editPhoto = photoId => this.setState({photoBeingEdited: photoId})
    _finishEditing = () => this.setState({
        videoBeingEdited: null, 
        editedVideoGsurl: '', 
        photoBeingEdited: null, 
        editedPhotoGsurl: '',
        editedPhotoThumbnail: '',
        editedPhotoPath: '',
        editedText: '',
    })
    _handleAddVideo = e => {
        e.preventDefault()
        this.stillPoseCollection.add({
            path: this.state.editedVideoGsurl,
            position: this.videos.length + 1
        })
        this._finishEditing()
    }
    _stateToPhoto = () => ({
        gsurl: this.state.editedPhotoGsurl,
        thumbnail: this.state.editedPhotoThumbnail,
        path: this.state.editedPhotoPath,
        position: this.photos.length + 1
    })
    _handleAddPhoto = async e => {
        e.preventDefault()
        const snaps = await this.documentationCollection.where('gsurl', '==', this.state.editedPhotoGsurl).get()
        if (snaps.empty) {
            this.documentationCollection.add(this._stateToPhoto())
        } else {
            this.documentationCollection.doc(snaps.docs[0].id).set(this._stateToPhoto(), {merge: true})
        }
        this._finishEditing()
    }
    _handleEditPhoto = e => {
        e.preventDefault()
        this.documentationCollection.doc(this.state.photoBeingEdited).set(this._stateToPhoto(), {merge: true})
        this._finishEditing()
    }

    componentDidMount () {
        this.intervalId = setInterval(() => {
			!this.state.isAdmin && this.setState({isOn: !this.state.isOn})
		}, 4000)
        this.seriesUnsub = this.seriesRef.onSnapshot(snap => {
            if (snap.get('description') !== null && snap.get('description') !== this.state.seriesDescription) {
                this.setState({seriesDescription: snap.get('description')})
            }
        })
        this.videoCollectionUnsub = this.stillPoseCollection.onSnapshot(snap => {
            this.videos = sortBy(snap.docs, doc => -doc.get('position'))
            this.forceUpdate()
        })
        this.documentationCollectionUnsub = this.documentationCollection.onSnapshot(snap => {
            this.photos = sortBy(snap.docs, doc => -doc.get('position'))
            this.forceUpdate()
        })
        this.authUnsub = watchUserRole(isAdmin => this._setIsAdmin(isAdmin))
    }

    componentWillUnmount () {
        clearInterval(this.intervalId)
        this.seriesUnsub()
        this.videoCollectionUnsub()
        this.documentationCollectionUnsub()
        this.authUnsub()
    }

    render () {
        return <div>
            <div style={{
                maxWidth: '900px',
	            margin: '0 auto',
	            padding: '16px',
            }}>
                <Header />
            </div>

            <div className={[style.classContainer, this.state.isOn && style.bgOn].join(' ')}>
                <div style={{
                    maxWidth: '1000px',
                    margin: '0 auto',
                    paddingTop: '32px'
                }}>
                    <div style={{
                        margin: '0 auto',
                        textAlign: 'center'
                    }}>
                        <Neon text={<i className={style.iconClass} />} blinkingText={<i className={style.iconY} />} />
                    </div>
                    
                    <div className={style.description}>{this.state.seriesDescription}</div>

                    <div>
                        <img alt='Class installation at Plug In ICA, Wpg MB' style={{maxWidth: '100%'}} src='https://firebasestorage.googleapis.com/v0/b/karenasher-93faf.appspot.com/o/ad-hoc%2F05.jpg?alt=media&token=ffce8d3f-8204-44a3-834b-180ccdc55936' />
                    </div>

                    <div id='documentation' style={{paddingTop: '64px'}}>
                        <PhotoSeries 
                            showHeader={false}
                            headerBg='rgb(191, 0, 0)'
                            seriesName='Photography'
                            bucketName='art/class/photos'
                            docID='class'
                        />
                    </div>

                    <div id='videos' className={style.videosContainer}>
                        <iframe title='Hailey' src="https://player.vimeo.com/video/367497965" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <iframe title='M & T' src="https://player.vimeo.com/video/367497546" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <iframe title='Ming' src="https://player.vimeo.com/video/367498103" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <iframe title='Pink Girls' src="https://player.vimeo.com/video/367499459" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <iframe title='Silly' src="https://player.vimeo.com/video/367499137" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <iframe title='Popcorn' src="https://player.vimeo.com/video/367498513" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        <iframe title='Chaos' src="https://player.vimeo.com/video/367496966" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>

                </div>
            </div>
        </div>
    }
}

export default Class
