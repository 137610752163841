import React, { Component } from 'react'
import slug from 'slug'
import style from './TextItem.module.css'
import Heading from './Heading'
import Button from './Button'
import Link from './Link'
import Markdown from './Markdown'
import { rewriteSignedUrls } from '../utils'

export default class TextItem extends Component {
    render () {
        const {item, isAdmin, deleteItem, editItem, shouldOwnPage=false} = this.props

        return <div className={[style.sectionContent, shouldOwnPage && style.ownPage].join(' ')}>
            {!!item.get('image') && <div className={style.imageContainer}>
                <img alt='' className={style.displayedImage} src={rewriteSignedUrls(item.get('image'))} />
            </div>}
            <div className={style.displayedContent}>
                <div className={style.itemDate}>{item.get('date')}</div>

                {!shouldOwnPage ? <div>
                    <Link inline to={'/press/' + slug(item.get('title'))}>
                        <Heading sub>{item.get('title')}</Heading>
                    </Link>
                    <Markdown 
                        markdown={item.get('markdownText') ? item.get('markdownText') : ''} 
                        append={!!item.get('title') && !!item.get('body') 
                            ? <Link inline to={'/press/' + slug(item.get('title'))}>Read more</Link> 
                            : null
                        }
                    />
                </div> : !!item.get('title') && <Heading sub>{item.get('title')}</Heading>}

                {!!shouldOwnPage && <Markdown markdown={item.get('body') ? item.get('body') : ''} />}
                
                {isAdmin && <div>
                    <Button label='Delete' variant='delete' onClick={deleteItem} />
                    <Button label='Edit' onClick={editItem} />
                </div>}
            </div>
        </div>
    }
}