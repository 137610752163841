export function rewriteSignedUrls (url) {
    if (url.indexOf('https://storage.googleapis.com/karenasher-93faf.appspot.com') !== 0) {
        return url
    }

    let newUrl = 'https://firebasestorage.googleapis.com/v0/b/karenasher-93faf.appspot.com/o'
    const bucketAndFileName = url.replace('https://storage.googleapis.com/karenasher-93faf.appspot.com', '').split('?')[0]

    return newUrl + bucketAndFileName + '?alt=media'
}

export const viewportWidth = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 );
