import React from "react";
import style from './PageContainer.module.css'
import Header from "./Header"
import Heading from "./Heading"

const PageContainer = ({children, pageTitle}) => <div style={{
    maxWidth: '900px',
	margin: '0 auto',
    padding: '16px'
}}>
    <Header />
    <Heading large padTop>{pageTitle}</Heading>
    <div className={style.container}>{children}</div>
</div>

export default PageContainer
